<style lang="scss"></style>

<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.186 48.1861C38.3345 48.1861 47.3721 39.1485 47.3721 28C47.3721 16.8516 38.3345 7.81396 27.186 7.81396C16.0376 7.81396 7 16.8516 7 28C7 39.1485 16.0376 48.1861 27.186 48.1861Z"
      fill="#6383A8"
    />
    <path
      d="M26.0504 35.4655H27.4605C27.4605 35.4655 27.8854 35.4193 28.1022 35.1868C28.3083 34.9699 28.3083 34.5686 28.3083 34.5686C28.3083 34.5686 28.2779 32.6777 29.161 32.4C30.0314 32.1242 31.1353 34.2251 32.3119 35.0327C33.2107 35.6499 33.8868 35.5175 33.8868 35.5175L37.0377 35.4743C37.0377 35.4743 38.6882 35.3713 37.9081 34.075C37.8482 33.972 37.4655 33.1183 35.5815 31.3667C33.6071 29.5405 33.8721 29.8349 36.2448 26.6693C37.6863 24.7411 38.276 23.5626 38.0847 23.0622C37.9218 22.5912 36.8483 22.7079 36.8483 22.7079L33.3137 22.7384C33.3137 22.7384 33.0488 22.7079 32.8575 22.8267C32.6661 22.9434 32.5493 23.2103 32.5493 23.2103C32.5493 23.2103 31.9881 24.7107 31.2384 25.9775C29.6458 28.6554 29.0128 28.8026 28.7626 28.626C28.1699 28.2423 28.322 27.0756 28.322 26.2395C28.322 23.648 28.7185 22.5598 27.5556 22.2801C27.173 22.1908 26.8874 22.1329 25.9022 22.1172C24.6364 22.1035 23.5687 22.127 22.9633 22.4224C22.5659 22.6216 22.2568 23.0622 22.4344 23.0847C22.6699 23.1142 23.1988 23.228 23.4785 23.6117C23.8464 24.1082 23.8307 25.217 23.8307 25.217C23.8307 25.217 24.0378 28.2747 23.346 28.6544C22.8603 28.9115 22.2126 28.3797 20.7996 25.9501C20.0784 24.7137 19.5328 23.3301 19.5328 23.3301C19.5328 23.3301 19.4297 23.0789 19.2394 22.9317C19.0166 22.7698 18.6928 22.7109 18.6928 22.7109L15.3221 22.7403C15.3221 22.7403 14.8217 22.756 14.646 22.9758C14.4831 23.1672 14.6303 23.5793 14.6303 23.5793C14.6303 23.5793 17.266 29.7486 20.255 32.8553C22.9927 35.7029 26.0994 35.5194 26.0994 35.5194L26.0504 35.4655Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconVkRounded',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
