<style lang="scss"></style>

<template>
  <svg
    width="153"
    height="22"
    viewBox="0 0 153 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.65904 3.68714C6.31006 3.68714 6.05292 3.79734 5.88761 4.01775C5.74067 4.21979 5.63047 4.55959 5.557 5.03714L3.76618 16.3331C3.65597 17.1045 3.94067 17.4902 4.62026 17.4902C5.20802 17.4902 5.56618 17.1045 5.69475 16.3331L7.48557 5.03714C7.61414 4.13714 7.33863 3.68714 6.65904 3.68714ZM6.35598 0.105469H7.09985C8.69782 0.105469 9.83659 0.573849 10.5162 1.51059C11.1958 2.44732 11.4162 3.71469 11.1774 5.31265L9.46925 16.1127C8.89986 19.4739 7.39373 21.1545 4.95087 21.1545H4.20699C2.66414 21.1545 1.52536 20.6953 0.790663 19.7769C0.0743359 18.8586 -0.155256 17.5912 0.101887 15.9749L1.7825 5.3402C2.05801 3.5953 2.53556 2.2912 3.21516 1.42793C3.91312 0.546299 4.96006 0.105469 6.35598 0.105469Z"
      fill="#D7D7D7"
    />
    <path
      d="M17.5135 12.6412H15.4472L14.1523 20.8514H10.5982L13.8492 0.35344H18.5604C20.0115 0.35344 20.9941 0.702423 21.5084 1.40038C22.0411 2.07998 22.188 3.08102 21.9492 4.40347L21.288 8.50858C21.086 9.84939 20.7002 10.878 20.1309 11.5943C19.5615 12.2923 18.689 12.6412 17.5135 12.6412ZM16.9074 3.52182L15.9982 9.30756H16.7421C17.0911 9.30756 17.3298 9.2249 17.4584 9.0596C17.587 8.89429 17.6788 8.6096 17.7339 8.20551L18.2849 4.73408C18.4135 3.92592 18.1655 3.52182 17.5411 3.52182H16.9074Z"
      fill="#D7D7D7"
    />
    <path
      d="M24.3348 0.35344H31.9113L31.3327 3.96265H27.3378L26.6215 8.42592H29.8174L29.2113 12.1178H26.0154L25.2164 17.1596H29.4593L28.8531 20.8514H21.0837L24.3348 0.35344Z"
      fill="#D7D7D7"
    />
    <path
      d="M33.7979 0.35344H37.1316L37.903 10.9606L39.5836 0.35344H42.752L39.501 20.8514H36.4704L35.5336 9.44531L33.7153 20.8514H30.5469L33.7979 0.35344Z"
      fill="#D7D7D7"
    />
    <path
      d="M48.9716 20.8514L52.2227 0.35344H56.9614C59.8084 0.35344 60.9839 1.81367 60.488 4.73408L58.6696 16.2229C58.4308 17.8392 58.0176 19.0147 57.4298 19.7494C56.8604 20.4841 55.8778 20.8514 54.4819 20.8514H48.9716ZM55.2533 3.60447L53.0492 17.4351H53.7931C54.2155 17.4351 54.5002 17.3341 54.6472 17.1321C54.8125 16.93 54.941 16.5259 55.0329 15.9198L56.7686 4.98204C56.9155 4.06367 56.6768 3.60447 56.0523 3.60447H55.2533Z"
      fill="#D7D7D7"
    />
    <path
      d="M65.1814 20.8514L65.3467 17.4075H63.1701L62.316 20.8514H59.0926L64.8507 0.35344H69.0661L68.5977 20.8514H65.1814ZM64.0793 13.881H65.512L65.815 6.96572L64.0793 13.881Z"
      fill="#D7D7D7"
    />
    <path
      d="M71.4103 0.35344H80.0613L79.4827 3.96265H76.9756L74.3031 20.8514H70.749L73.4215 3.96265H70.8317L71.4103 0.35344Z"
      fill="#D7D7D7"
    />
    <path
      d="M82.7893 20.8514L82.9546 17.4075H80.778L79.924 20.8514H76.7005L82.4587 0.35344H86.674L86.2056 20.8514H82.7893ZM81.6872 13.881H83.1199L83.423 6.96572L81.6872 13.881Z"
      fill="#D7D7D7"
    />
    <path
      d="M93.5391 20.498L96.7901 0H101.887C103.136 0 104.027 0.293875 104.559 0.88163C105.111 1.45102 105.276 2.37857 105.055 3.66429L104.642 6.28164C104.385 7.91633 103.659 8.90818 102.466 9.25716C103.715 9.71634 104.22 10.7908 103.981 12.4806L103.457 15.897C103.182 17.5317 102.769 18.7072 102.218 19.4235C101.685 20.1398 100.886 20.498 99.8207 20.498H93.5391ZM99.3799 11.3235H98.5534L97.5891 17.247H98.6085C98.9391 17.247 99.187 17.1735 99.3524 17.0266C99.5177 16.8796 99.6462 16.5306 99.7381 15.9796L100.262 12.6735C100.335 12.2143 100.307 11.8745 100.179 11.6541C100.05 11.4337 99.784 11.3235 99.3799 11.3235ZM100.647 3.14082H99.8483L99.0768 8.01736H99.9585C100.252 8.01736 100.473 7.94389 100.62 7.79695C100.785 7.65001 100.914 7.36532 101.005 6.94287L101.446 4.21531C101.556 3.49898 101.29 3.14082 100.647 3.14082Z"
      fill="#00C1F5"
    />
    <path
      d="M109.856 20.498L110.022 17.0541H107.845L106.991 20.498H103.768L109.526 0H113.741L113.273 20.498H109.856ZM108.754 13.5276H110.187L110.49 6.61226L108.754 13.5276Z"
      fill="#00C1F5"
    />
    <path
      d="M116.085 0H124.736L124.158 3.60919H121.651L118.978 20.498H115.424L118.097 3.60919H115.507L116.085 0Z"
      fill="#00C1F5"
    />
    <path
      d="M126.167 0H134.818L134.24 3.60919H131.733L129.06 20.498H125.506L128.179 3.60919H125.589L126.167 0Z"
      fill="#00C1F5"
    />
    <path
      d="M136.445 0H139.999L137.327 16.8061H141.129L140.523 20.498H133.194L136.445 0Z"
      fill="#00C1F5"
    />
    <path
      d="M145.424 0H153.001L152.422 3.60919H148.427L147.711 8.07246H150.907L150.301 11.7643H147.105L146.306 16.8061H150.549L149.943 20.498H142.173L145.424 0Z"
      fill="#00C1F5"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconGameLogo',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
