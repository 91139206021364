<style lang="scss"></style>

<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.186 48.1861C16.0164 48.1861 7 39.1696 7 28C7 16.8304 16.0164 7.81396 27.186 7.81396C38.3557 7.81396 47.3721 16.8304 47.3721 28C47.3721 39.1696 38.3557 48.1861 27.186 48.1861Z"
      fill="#3B5998"
    />
    <path
      d="M24.3619 38.9677H28.8701V28H31.898L32.3017 24.2319H28.8701V22.3479C28.8701 21.3386 28.9374 20.8003 30.3504 20.8003H32.2344V17.0322H29.2065C25.573 17.0322 24.2946 18.849 24.2946 21.9442V24.2319H22.0068V28H24.2946V38.9677H24.3619Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconFbRounded',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
