<style lang="scss" scoped>
.g-social-share {
  @apply text-left overflow-hidden;
  &__content {
    @apply px-9 py-8;
  }
  &__title {
    @apply font-druk_text_cy;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: 0.06em;
    color: #ffffff;
    max-width: 482px;
  }
  &__item-container {
    --side-indent: 6px;
    @apply mt-8;
    margin-left: calc(-1 * var(--side-indent));
    margin-right: calc(-1 * var(--side-indent));
  }
  &__item {
    @apply inline-block align-top;
    margin-left: var(--side-indent);
    margin-right: var(--side-indent);
    &:hover {
      transform: scale(1.1);
    }
  }
  &__preview {
    @apply relative bg-no-repeat;
    background-image: url('/images/bg-cards.png'), url('/images/blur-1.svg');
    background-position: top right, bottom left;
    background-size: 383px, cover;
    padding: 185px 310px 14px 24px;
    &-title {
      @apply font-druk_text_cy uppercase mb-1;
      font-weight: 500;
      font-size: 34px;
      line-height: 109%;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
    &-subtitle {
      @apply font-graphik_lv_regular;
      font-size: 18px;
      line-height: 26px;
      color: #ffffff;
    }
    &-logo {
      @apply absolute;
      top: 26px;
      left: 26px;
    }
  }
}
</style>

<template>
  <div class="result-block g-social-share">
    <div class="g-social-share__preview">
      <icon-game-logo class="g-social-share__preview-logo" />
      <div class="g-social-share__preview-title">
        Пройди отборочный тур Open Data Battle
      </div>
      <div class="g-social-share__preview-subtitle">
        и поборись за крутые призы!
      </div>
    </div>
    <div class="g-social-share__content">
      <div class="g-social-share__title">
        {{ title }}
      </div>
      <div class="g-social-share__item-container">
        <a
          v-for="social in socialNetworks"
          :key="social"
          :href="shareUrlLink(social)"
          class="g-social-share__item"
          target="_blank"
        >
          <slot :name="social">
            <component :is="`icon-${social}-rounded`" />
          </slot>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import IconFbRounded from '@/components/Icons/IconFbRounded.vue'
import IconTwRounded from '@/components/Icons/IconTwRounded.vue'
import IconVkRounded from '@/components/Icons/IconVkRounded.vue'
import IconGameLogo from '../Icons/IconGameLogo.vue'

const DEFAULT_SOCIAL_NETWORKS = ['fb', 'vk', 'tw']
const FB_BASE_SHARE_LINK = 'https://www.facebook.com/sharer/sharer.php?u='
const VK_BASE_SHARE_LINK = 'https://vk.com/share.php?url=$'
const TW_BASE_SHARE_LINK = 'https://www.twitter.com/share?url='

export default {
  name: 'SocialShareBlock',
  components: { IconFbRounded, IconVkRounded, IconTwRounded, IconGameLogo },
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      default: 'Расскажи друзьям, посмотрим смогут ли они лучше',
    },
    shareUrl: { type: String },
    socialNetworks: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every((item) => DEFAULT_SOCIAL_NETWORKS.includes(item))
      },
    },
  },
  methods: {
    shareUrlLink(platform) {
      const url = this.shareUrl || window.location.href
      switch (platform) {
        case 'tw':
          return TW_BASE_SHARE_LINK + url
        case 'vk':
          return VK_BASE_SHARE_LINK + url
        case 'fb':
          return FB_BASE_SHARE_LINK + url
      }
    },
  },
  computed: {},
  watch: {},
  mounted() {},
}
</script>
