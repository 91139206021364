<style lang="scss">
.results {
  @apply mx-auto;
  padding-top: 44px;
  padding-bottom: 135px;
  max-width: 625px;
  color: #ffffff;
  &__title {
    @apply mb-4 font-graphik_lv_bold;
    font-weight: 500;
    font-size: 74px;
    line-height: 74px;
  }
  &__subtitle {
    @apply mx-auto;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 42px;
    max-width: 337px;
  }
  &__prizes {
    @apply px-13 py-10 text-left;
    &-title {
      max-width: 352px;
      margin-bottom: 56px;
    }
    &-subtitle {
      @apply mb-6;
      max-width: 432px;
      font-size: 18px;
      line-height: 30px;
      color: #dadde6;
    }
  }
}
</style>

<template>
  <div class="results">
    <div class="results__title">{{ title }}</div>
    <div class="results__subtitle">{{ subtitle }}</div>
    <div class="result-block results__prizes">
      <div class="result-block__title results__prizes-title">
        {{ prizes.title }}
      </div>
      <div class="results__prizes-subtitle">{{ prizes.subtitle }}</div>
      <div class="-m-2">
        <RewardItem
          v-for="(item, idx) in rewards"
          :key="`rewards_${idx}`"
          :title="item.title"
          :place="item.place"
          :image="item.image"
          class="w-234px inline-block m-2"
        />
      </div>
    </div>
    <feedback-block />
    <social-share-block :socialNetworks="['fb', 'vk', 'tw']" />
    <modal-content-intention class="result-block" />
    <router-link
      class="btn-primary btn-primary--shadow w-max mx-auto mt-14"
      :to="{ name: 'Issues' }"
      >Вернутся к заданиям</router-link
    >
  </div>
</template>

<script>
import ModalContentIntention from '../../components/ModalContentIntention.vue'
import FeedbackBlock from '../../components/ResultsPage/FeedbackBlock.vue'
import SocialShareBlock from '../../components/ResultsPage/SocialShareBlock.vue'
import RewardItem from '@/components/RewardItem.vue'
import { REWARDS } from '@/consts.js'

export default {
  name: 'ResultsPage',
  components: {
    FeedbackBlock,
    SocialShareBlock,
    ModalContentIntention,
    RewardItem,
  },
  data() {
    return {
      title: 'Ты дошёл до конца',
      subtitle: 'Ты настоящий знаток Data Science',
      prizes: {
        title: 'Отборочный тур завершится 29 октября',
        subtitle:
          'Нереально крутая трансляция финала, с приглашенными спикерами и классными ведущими пройдет 30-31 октября. Прошедшие в финал участники смогут побороться за призовые места.',
      },
      rewards: REWARDS,
    }
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
