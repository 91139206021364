<style lang="scss">
.reward-item {
  @apply rounded-md relative;
  background-color: #191818;
  border: 1px solid #313131;

  &__container {
    @apply flex items-center justify-between py-3 pl-5;
    height: 112px;
    padding-right: 69px;
  }

  &__info {
    @apply text-left pr-3;
  }

  &__place {
    @apply font-druk_text_cy text-light-blue uppercase;
    font-size: 17px;
    letter-spacing: 0.03em;
  }

  &__title {
    @apply text-14 leading-5 text-white mt-1.5;
  }
  &__image {
    @apply absolute bg-center bg-no-repeat;
    top: 0;
    right: 0;
    width: 88px;
    height: 96px;
    background-size: auto 80%;
  }
}

@screen lg {
  .reward-item {
    &__container {
      height: 96px;
    }
  }
}
</style>

<template>
  <div class="reward-item">
    <div class="reward-item__container">
      <div class="reward-item__info">
        <div class="reward-item__place">{{ place }}</div>
        <div class="reward-item__title">{{ title }}</div>
      </div>
      <div
        class="reward-item__image"
        :style="{ backgroundImage: `url('${image}')` }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RewardItem',
  props: {
    title: String,
    place: String,
    image: String,
  },
}
</script>
