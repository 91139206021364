<style lang="scss">
.feedback-block {
  @apply py-8;
  padding-left: 52px;
  padding-right: 52px;
}
.feedback-thx {
  @apply font-druk_text_cy text-left;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  color: #ffffff;
  height: 42px;
}

.btn-smile {
  @apply mr-2;
  &:last-child {
    @apply mr-0;
  }
  &:hover:not(:disabled) {
    transform: scale(1.1);
  }
  &:disabled {
    @apply cursor-not-allowed;
    &:not(.selected) {
      filter: grayscale(1);
    }
  }
  &:focus {
    outline: none;
  }
}
</style>

<template>
  <div class="result-block feedback-block">
    <div class="result-block__title mb-6">Как тебе батл?</div>
    <button
      v-for="i in 5"
      :key="i"
      :disabled="selected"
      class="btn-smile"
      :class="{ selected: i === selected }"
      @click="setSelected(i)"
    >
      <component :is="`icon-smile-rating-${i}`" />
    </button>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <div v-if="selected">
        <textarea
          v-model="comment"
          class="geecko-textarea mt-6"
          placeholder="Напиши отзыв"
          :disabled="done"
        ></textarea>
        <button
          v-if="selected && !done"
          class="btn-primary mt-8"
          :disabled="!comment || done"
          @click="estimate"
        >
          Отправить
        </button>
        <div v-if="selected && done" class="feedback-thx mt-8">
          Спасибо за отзыв!
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IconSmileRating1 from '../Icons/IconSmileRating1.vue'
import IconSmileRating2 from '../Icons/IconSmileRating2.vue'
import IconSmileRating3 from '../Icons/IconSmileRating3.vue'
import IconSmileRating4 from '../Icons/IconSmileRating4.vue'
import IconSmileRating5 from '../Icons/IconSmileRating5.vue'
import { sendFeedback } from '@/api/sendFeedback'
import { ref, reactive, toRefs, computed, watch } from '@vue/composition-api'

export default {
  name: 'FeedbackBlock',
  components: {
    IconSmileRating1,
    IconSmileRating2,
    IconSmileRating3,
    IconSmileRating4,
    IconSmileRating5,
  },
  data() {
    return {}
  },
  props: {},
  setup(props, ctx) {
    const state = reactive({
      isLoading: false,
      error: null,
      success: null,
    })
    const selected = ref(null)
    const comment = ref(null)
    const done = ref(false)

    const estimate = async () => {
      state.isLoading = true
      state.error = null
      state.success = null
      try {
        const response = await sendFeedback({
          rating: selected.value,
          comment: comment.value,
        })
        state.success = true
        if (comment.value) {
          done.value = true
        }
      } catch (e) {
        state.error = e
      } finally {
        state.isLoading = false
      }
    }

    const setSelected = (id) => {
      selected.value = id
    }

    watch(selected, estimate)

    return { ...toRefs(state), estimate, selected, setSelected, comment, done }
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
  watch: {
    success: function (val) {
      if (val) {
        this.$notify({
          title: 'Ответ успешно отправлен',
          type: 'success',
        })
      }
    },
    error: function (val) {
      if (val) {
        this.$notify({
          title: 'Ошибка при отправке',
          type: 'error',
        })
      }
    },
  },
}
</script>
