<style lang="scss"></style>

<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.51172 28C6.51172 16.8516 15.5493 7.81396 26.6978 7.81396C37.8462 7.81396 46.8838 16.8516 46.8838 28C46.8838 39.1485 37.8462 48.1861 26.6978 48.1861C15.5493 48.1861 6.51172 39.1485 6.51172 28Z"
      fill="#55ACEE"
    />
    <path
      d="M26.0929 24.2211L26.1353 24.9196L25.4293 24.8341C22.8595 24.5062 20.6145 23.3944 18.7083 21.527L17.7764 20.6004L17.5364 21.2847C17.0281 22.8099 17.3528 24.4207 18.4118 25.5041C18.9766 26.1028 18.8495 26.1883 17.8753 25.8319C17.5364 25.7179 17.2399 25.6324 17.2116 25.6751C17.1128 25.7749 17.4517 27.0721 17.72 27.5853C18.0871 28.298 18.8354 28.9965 19.6544 29.4099L20.3462 29.7377L19.5273 29.752C18.7366 29.752 18.7083 29.7662 18.793 30.0656C19.0754 30.9921 20.1909 31.9757 21.4334 32.4034L22.3089 32.7027L21.5464 33.1589C20.4168 33.8146 19.0896 34.1852 17.7623 34.2137C17.1269 34.228 16.6045 34.285 16.6045 34.3278C16.6045 34.4703 18.3271 35.2686 19.3296 35.5822C22.3371 36.5087 25.9094 36.1096 28.5921 34.5273C30.4983 33.4012 32.4045 31.1632 33.294 28.9965C33.7741 27.8418 34.2541 25.7321 34.2541 24.7201C34.2541 24.0643 34.2965 23.9788 35.0872 23.1948C35.5532 22.7386 35.9909 22.2397 36.0756 22.0972C36.2168 21.8263 36.2027 21.8263 35.4826 22.0687C34.2824 22.4963 34.1129 22.4393 34.706 21.7978C35.1437 21.3417 35.6661 20.5149 35.6661 20.2726C35.6661 20.2298 35.4543 20.3011 35.2143 20.4294C34.9601 20.5719 34.3953 20.7857 33.9717 20.914L33.2093 21.1564L32.5174 20.686C32.1362 20.4294 31.5996 20.1443 31.3172 20.0587C30.5971 19.8592 29.4958 19.8877 28.8463 20.1158C27.0813 20.7572 25.9659 22.4108 26.0929 24.2211Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTwRounded',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
